export default {
	mobile: ({ commit }, { value }) => {
		commit('mobile', { value: value });
	},
	title: ({ commit }, { value }) => {
		commit('title', { value: value });
	},
	authenticated: ({ commit }, { value }) => {
		commit('authenticated', { value: value });
	},
	user: ({ commit }, { value }) => {
		commit('user', { value: value });
	},
	ultimologin: ({ commit }, { value }) => {
		commit('ultimologin', { value: value });
	},
	keepLogged: ({ commit }, { value }) => {
		commit('keepLogged', { value: value });
	},
	getUserInfo: ({ commit }, { value }) => {
		commit('getUserInfo', { value: value });
	},
	downloadMobileAppPage: ({ commit }, { value }) => {
		commit('downloadMobileAppPage', { value: value });
	},
	logoutUser: ({ commit }, { value }) => {
		commit('logoutUser', { value: value });
	},
	openChoiceFilial: ({ commit }, { value }) => {
		commit('openChoiceFilial', { value: value });
	},
	openCategorias: ({ commit }, { value }) => {
		commit('openCategorias', { value: value });
	},
	openMeusClientes: ({ commit }, { value }) => {
		commit('openMeusClientes', { value: value });
	},
	cliente: ({ commit }, { value }) => {
		commit('cliente', { value: value });
	},
	clienteSituacao: ({ commit }, { value }) => {
		commit('clienteSituacao', { value: value });
	},
	filialList: ({ commit }, { value }) => {
		commit('filialList', { value: value });
	},
	filialSelected: ({ commit }, { value }) => {
		commit('filialSelected', { value: value });
	},
	tabpreco: ({ commit }, { value }) => {
		commit('tabpreco', { value: value });
	},
	tipopedido: ({ commit }, { value }) => {
		commit('tipopedido', { value: value });
	},
	prazopagtoList: ({ commit }, { value }) => {
		commit('prazopagtoList', { value: value });
	},
	prazopagtoselected: ({ commit }, { value }) => {
		commit('prazopagtoselected', { value: value });
	},
	formapagtoList: ({ commit }, { value }) => {
		commit('formapagtoList', { value: value });
	},
	formapagtoselected: ({ commit }, { value }) => {
		commit('formapagtoselected', { value: value });
	},
	friendlyUrl: ({ commit }, { value }) => {
		commit('friendlyUrl', { value: value });
	},
	currentModule: ({ commit }, { value }) => {
		commit('currentModule', { value: value });
	},
	pedidopendente: ({ commit }, { value }) => {
		commit('pedidopendente', { value: value });
	},
	itenspedido: ({ commit }, { value }) => {
		commit('itenspedido', { value: value });
	},
	modulosPDA: ({ commit }, { value }) => {
		commit('modulosPDA', { value: value });
	},
	errosValorZerado: ({ commit }, { value }) => {
		commit('errosValorZerado', { value: value });
	},
	appBarClientStoreKey: ({ commit }, { value }) => {
		commit('appBarClientStoreKey', { value: value });
	},
	userModalKey: ({ commit }, { value }) => {
		commit('userModalKey', { value: value });
	},
	userMenuKey: ({ commit }, { value }) => {
		commit('userMenuKey', { value: value });
	},
	recaptchaToken: ({ commit }, { value }) => {
		commit('recaptchaToken', { value: value });
	},
	recaptchaAction: ({ commit }, { value }) => {
		commit('recaptchaAction', { value: value });
	},
	numDestinatarios: ({ commit }, { value }) => {
		commit('numDestinatarios', { value: value });
	},
	hasIntegracao: ({ commit }, { value }) => {
		commit('hasIntegracao', { value: value });
	},
	filterProdutosScrenState: ({ commit }, { value }) => {
		commit('filterProdutosScrenState', { value: value });
	},
	produtosIndisponiveisToUpdate: ({ commit }, { value }) => {
		commit('produtosIndisponiveisToUpdate', { value: value });
	},
	configs: ({ commit }, { value }) => {
		commit('configs', { value: value });
	},
	parametrosClientStore: ({ commit }, { value }) => {
		commit('parametrosClientStore', { value: value });
	},
};