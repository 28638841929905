// Para carregamento por demanda
let lazyLoad = (view) => {
	return () => import(`@/pages/views/${view}`);
};

export default [
	{
		path: '/info',
		name: 'Info',
		component: lazyLoad('Info.vue'),
		children: [
			{
				path: 'sobre-nos',
				name: 'SobreNos',
				component: lazyLoad('info/SobreNos.vue'),
				meta: {
					title: 'Sobre nós',
				},
			},
			{
				path: 'seja-parceiro',
				name: 'SejaParceiro',
				component: lazyLoad('info/SejaParceiro.vue'),
				meta: {
					title: 'Seja parceiro',
				},
			},
			{
				path: 'politica-de-privacidade',
				name: 'PoliticaDePrivacidade',
				component: lazyLoad('info/PoliticaDePrivacidade.vue'),
				meta: {
					title: 'Política de privacidade',
				},
			},
			{
				path: 'termos-de-uso',
				name: 'TermosDeUso',
				component: lazyLoad('info/TermosDeUso.vue'),
				meta: {
					title: 'Termos de uso',
				},
			},
			{
				path: 'politica-de-cookies',
				name: 'PoliticaDeCookies',
				component: lazyLoad('info/PoliticaDeCookies.vue'),
				meta: {
					title: 'Cookies',
				},
			},
		],
	},
];