import Vue from 'vue';
// https://vuetifyjs.com/en/
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

// Para criação de temas personalizados
export default new Vuetify({
	lang: {
		locales: { pt },
		current: 'pt',
	},
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#673ab7',
				secondary: '#ff5722',
				accent: '#9d9ba0',
				error: '#f44336',
				warning: '#ffc107',
				info: '#03a9f4',
				success: '#4caf50',
				registered: '#00e676',
				pending: '#ffab40',
			},
		},
	},
});
