// Para carregamento por demanda
let lazyLoad = (view) => {
	return () => import(`@/pages/views/${view}`);
};

export default [
	{
		path: '/placestore',
		name: 'PlaceStore',
		component: lazyLoad('PlaceStore.vue'),
		children: [
			{
				path: 'home',
				name: 'HomeClientes',
				component: lazyLoad('placestore/HomeClientes.vue'),
				meta: {
					title: 'PlaceStore',
					scrollToTop: true,
				},
			},
			{
				path: 'filter',
				name: 'FilterClientes',
				component: lazyLoad('placestore/FilterClientes.vue'),
				meta: {
					title: 'Clientes',
				},
			},
		],
	},
];
