// Para carregamento por demanda
let lazyLoad = (view) => {
	return () => import(`@/pages/views/${view}`);
};

export default [
	{
		path: '/user',
		name: 'User',
		component: lazyLoad('User.vue'),
		children: [
			{
				path: 'meus-dados',
				name: 'MeusDados',
				component: lazyLoad('clientstore/user/MeusDados.vue'),
				meta: {
					title: 'Meus dados',
					requiresAuthentication: true,
				},
			},
		],
	},
];
