<template>
	<v-app>
		<!-- Injeção de rotas -->
		<router-view />
		<!-- Aceite de cookie -->
		<CookieAccept v-if="!isForbiddenRoute()" />
	</v-app>
</template>

<script>
import { mapState } from 'vuex';

import CookieAccept from '@/pages/components/shared/CookieAccept.vue';

export default {
	name: 'App',

	components: {
		CookieAccept,
	},
	computed: {
		...mapState([
			'user',
			'mobile',
			'primaryColor',
			'getUserInfo',
			'logoutUser',
			'downloadMobileAppPage',
		]),
	},
	data() {
		return {
			lastRoute: null,
			debounce: null,
		};
	},
	mounted() {
		// nextTick é um utilitário para aguardar a próxima atualização do DOM.
		this.$nextTick(() => {
			// Adiciona escuta para a mudança de tamanho da tela
			window.addEventListener('resize', this.onResize);
		});
	},
	beforeDestroy() {
		// Remove escuta para a mudança de tamanho da tela
		window.removeEventListener('resize', this.onResize);
	},
	created() {
		// Salva no vuex se está num aparelho mobile
		this.$store.dispatch('mobile', { value: this.$isMobile() });

		// Verifica se existe o cookie de mater o usuário logado e manda informação para o vuex
		if (this.$cookies.get('keepLogged')) {
			this.$store.dispatch('keepLogged', { value: this.$cookies.get('keepLogged') });
		}

		// Inicializa verificação de tamanho de tela
		this.onResize();
	},
	watch: {
		getUserInfo(val) {
			if (val) this.userInfo();
		},
		logoutUser(val) {
			if (val) this.logout();
		},
	},
	methods: {
		isForbiddenRoute(){
			let forbiddenRoutes = ['DownloadMobileApp', 'PoliticaDeCookies', 'TermosDeUso', 'PoliticaDePrivacidade']
			return forbiddenRoutes.includes(this.$route.name)
		},
		onResize() {
			// Verificação de tamanho de tela para mostrar página de download de app caso seja mobile ou menor que 1024
			/**
			 * Debounce para não executar a mesma lógica ao mesmo tempo,
			 * pois vários elementos nessa tela chamam essa lógica.
			 * Debounce é quando a lógica só será executada a tanto tempo após não ser chamada;
			 * Ex: se eu chamar esta lógica 3 vezes em sequencia a cada 150 milisegundos, 
			 * a lógica será executada após 600 milisegundos da primeira chamada.
			 * Ou seja, o váriável debouce vai ser recriada novamente a cada chamada, 
			 * reiniciando o contador do setTimeout
			 */
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				if (screen.width < 1024 || window.innerWidth < 1024 || this.mobile) {
					if (this.$route.name != 'DownloadMobileApp') {
						this.lastRoute = this.$route;
						this.$router.push({ name: 'DownloadMobileApp' });
						this.$store.dispatch('downloadMobileAppPage', { value: true });
					}
				} else if (this.$route.name == 'DownloadMobileApp') {
					this.$router.push(this.lastRoute == null ? { name: 'HomeClientes' } : this.lastRoute);
					this.lastRoute = null;
					this.$store.dispatch('downloadMobileAppPage', { value: false });
				}
			}, 400);
		},

		userInfo() {
			// this.$axios.defaults.headers.Authorization = 'Authorization';
			// Pega as informações iniciais do usuario logado
			this.$axios
				.get('usuario')
				.then((response) => {
					if (response.data.data) {
						console.info('User:', response.data.data.nome);
						this.$store.dispatch('authenticated', { value: true });
						this.$store.dispatch('user', { value: response.data.data });
					} else {
						throw new this.ErrorMsg('without-data');
					}
				})
				.catch(() => {
					store.dispatch('authenticated', { value: false });
					console.info('User not found');
					this.$toast.info('Usuário não encontrado');
				})
				.finally(() => {
					this.$store.dispatch('getUserInfo', { value: false });
				});
		},

		logout() {
			// remove cookie
			this.$cookies.remove('keepLogged');
			this.$cookies.remove('authorization');
			// remove dados do vuex
			this.$store.dispatch('keepLogged', { value: false });
			this.$store.dispatch('user', { value: null });
			this.$store.dispatch('ultimologin', { value: null });
			this.$store.dispatch('logoutUser', { value: false });
			this.$store.dispatch('authenticated', { value: false });
			// remove código de autenticação no axios
			this.$axios.defaults.headers.Authorization = null;
			// caso não esteja na página inicial do sistema, manda pra lá
			const pushHome = (this.$route.name != 'HomeClientes' && this.$route.name != 'DownloadMobileApp' && this.$route.meta.requiresAuthentication);
			if (pushHome == true || pushHome == undefined) {
				this.$router.push({ name: 'HomeClientes' });
			}
			console.info('Logout/Cleaned successfully');
		},
	},
};
</script>
