import Vue from 'vue';
// https://github.com/kazupon/vue-i18n
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

// Configuração para pegar os asquivos da pasta locales
function loadLocaleMessages() {
	const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
	const messages = {};
	locales.keys().forEach((key) => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1) {
			const locale = matched[1];
			messages[locale] = locales(key);
		}
	});
	return messages;
}
// Formato de dedas com diferentes formatações
let dates = {
	short: {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	},
	average: {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		hour12: false,
	},
	long: {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		weekday: 'long',
		hour: 'numeric',
		minute: 'numeric',
		hour12: false,
	},
};

// Formatação de data por idioma
const dateTimeFormats = {
	'pt-BR': dates,
	'es-ES': dates,
	'en-US': dates,
};

// Formatação de moeda por idioma
const numberFormats = {
	'pt-BR': {
		currency: {
			style: 'currency',
			currency: 'BRL',
		},
		decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
    },
	},
	'es-ES': {
		currency: {
			style: 'currency',
			currency: 'EUR',
		},
		decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
    },
	},
	'en-US': {
		currency: {
			style: 'currency',
			currency: 'USD',
		},
		decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
    },
	},
};

// Exportando e e pegando linguagem padrão
export default new VueI18n({
	dateTimeFormats,
	numberFormats,
	locale: process.env.VUE_APP_I18N_LOCALE || 'pt-BR',
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pt-BR',
	messages: loadLocaleMessages(),
});
