import Vue from 'vue';
// Manipulação de cookies do navegador
// https://github.com/cmp-cc/vue-cookies
// default options config: { expires: '1d', path: '/', domain: '', secure: false, sameSite: 'Lax' }
let domain = window.location.hostname;
// console.info('Domain', domain);
import VueCookies from 'vue-cookies';
Vue.use(VueCookies, { expire: '3d', domain: domain, sameSite: 'Lax' });
// Página onde vai ser montado a aplicação
import App from './App.vue';
// Armazenamento global de dados durante a sessão
// https://vuex.vuejs.org/
import store from './vuex/vuex';
// Componentes para criação de sistemas para o vuejs
// https://vuetifyjs.com/
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

// Importa funções globais
import Util from '@/functions/Util';

import { recaptcha } from '@/functions/Recaptcha';
Vue.prototype.$recaptcha = recaptcha;
recaptcha.init();

// Comunicação http
// https://github.com/axios/axios
// https://axios-http.com/docs/intro
import axios from './plugins/axios';
Vue.prototype.$axios = axios;
// Verificação inicial de dados do usuário
axios
	.get('usuario')
	.then((response) => {
		if (response.data.data) {
			console.info('User:', response.data.data.nome);
			store.dispatch('authenticated', { value: true });
			store.dispatch('user', { value: response.data.data });
		} else {
			throw new this.ErrorMsg('without-data');
		}
	})
	.catch((error) => {
		store.dispatch('authenticated', { value: false });
		console.info(
			'User not found:',
			Util.getTranslation(error.response.data.message, 'without-data')
		);
	})
	.finally(() => {
		store.dispatch('getUserInfo', { value: false });
	});

// Gerenciamento de rotas
// https://router.vuejs.org/
import router from './router/router';

// Internacionalização, chaves de texto
// https://github.com/kazupon/vue-i18n
import i18n from './plugins/i18n';

// Toast notifications
// https://github.com/Maronato/vue-toastification/tree/main
// Types: success info warning error
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
const options = {
	position: 'bottom-right',
	closeOnClick: false,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	showCloseButtonOnHover: true,
	transition: 'Vue-Toastification__fade',
};
Vue.use(Toast, options);

// Tooltip
// https://github.com/KABBOUCHI/vue-tippy
import VueTippy, { TippyComponent } from 'vue-tippy';
Vue.use(VueTippy, {
	arrow: true,
	arrowType: 'round',
	theme: 'light-border',
	followCursor: true,
});
Vue.component('vueTippy', TippyComponent);
// Temas do tooltip
import 'tippy.js/themes/light.css';
import 'tippy.js/themes/light-border.css';
import 'tippy.js/themes/translucent.css';

// Detecta se está sendo usado em um aparelho mobile
// https://github.com/ajerez/vue-mobile-detection
import VueMobileDetection from 'vue-mobile-detection';
Vue.use(VueMobileDetection);

// Máscara como um plugin
// https://github.com/probil/v-mask
import VueMask from 'v-mask';
Vue.use(VueMask);
Vue.directive('mask', VueMask.VueMaskDirective);
Vue.filter('VMask', VueMask.VueMaskFilter);

// Criptografia
// https://github.com/tpenaranda/vue-cryptojs
import VueCryptojs from 'vue-cryptojs';
Vue.use(VueCryptojs);

// Leitor Qrcode
// https://github.com/gruhn/vue-qrcode-reader
import VueQrcodeReader from 'vue-qrcode-reader';
Vue.use(VueQrcodeReader);

// Trabalhando com datas
// https://github.com/rah-emil/vue-moment
import moment from 'moment';
moment.locale('pt-br');
import vueMoment from '@rah-emil/vue-moment';
Vue.use(vueMoment, { moment });

// https://www.npmjs.com/package/vue-image-zoomer/v/legacy
// https://github.com/samjonesigd/vue-image-zoomer
const ImageZoom = require('vue-image-zoomer').default;
Vue.component('image-zoom', ImageZoom);

// Criando url amigável
// https://github.com/simov/slugify
// slugify('Texto para transformar', { lower: true, strict: true })
import slugify from 'slugify';

// importa estilos scss
import '@/scss/styles.scss';

// Filtros
// Quebra o texto na posição desejada
Vue.filter('truncate', (str, length) => {
	return str.length > length ? str.substring(0, length) + '...' : str;
});

// exportando funções globalmente
Vue.mixin({
	methods: {
		...Util,
		slugify,
	},
});

new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: (h) => h(App),
}).$mount('#app');
