import axios from 'axios';
import store from '@/vuex/vuex';

// se existir cookie com código de autenticação passa para o axios
let Authorization = null;
if ($cookies.get('authorization')) Authorization = $cookies.get('authorization');

// Full config:  https://github.com/axios/axios#request-config
const api = axios.create({
	// pega url do server
	baseURL: process.env.VUE_APP_API_URL || '',
	headers: {
		'Content-Type': 'application/json',
		Authorization: Authorization,
	},
	timeout: 60 * 1000, // Timeout 1 min
	withCredentials: true, // Check cross-site Access-Control
});

// captura requisição e faz pré-intervensões
api.interceptors.request.use(
	(request) => {
		// se estiver dentro de uma loja passa as informações no cabeçalho da requisição
		// se precisar pegar da rota router.currentRoute.params.friendlyurl
		if ($cookies.get('friendlyurl')) {
			request.headers['Authorization-Empresa'] = $cookies.get('friendlyurl');
			if ($cookies.get('filial') && $cookies.get('filial') != 'catalogo') {
				request.headers['Authorization-Empresa-Filial'] = $cookies.get('filial');
			}
		}
		
		//só lê action do vuex caso haja token armazenado
		if(store.state['recaptchaToken'] != ""){
			//verifica action para evitar condição de corrida
			if(store.state['recaptchaAction'] == request.url){
				request.headers['GR-Token'] = store.state['recaptchaToken'];

				//token é de uso único, então é preciso limpar vuex após utilizar token
				store.dispatch('recaptchaToken', { value: "" });
				store.dispatch('recaptchaAction', { value: "" });
			}
		}
		return request;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// captura resposta e faz pré-intervensões
api.interceptors.response.use(
	(response) => {
		// caso venha código de autenticação na resposta do server
		if (response.headers['authorization-response']) {
			// pega o token vindo do server
			api.defaults.headers.Authorization = response.headers['authorization-response'];
			// Calculando tempo dependendo da opção de manter conectado
			let timeToExpire = '1h'; // uma hora
			if ($cookies.get('keepLogged') && $cookies.get('keepLogged') == 'true') {
				timeToExpire = '1m'; // um mês
			}
			// Adiciona o cookie atualizado no token para ser usado na primeira requisição
			$cookies.set('authorization', response.headers['authorization-response'], timeToExpire);
		}

		return response;
	},
	(error) => {
		//faz logout se uma request retornar status 403. porém, se a request é de "usuario", não precisa chamar logout, visto que não foi feito login
		if (error.response.status === 403 && error.config.url != "usuario") {
			store.dispatch('logoutUser', { value: true });
		}

		return Promise.reject(error);
	}
);

export default api;
