<template>
	<div class="cookie-accept elevation-3" v-if="showCookieAccept">
		<v-card dark color="primary">
			<v-card-title primary-title> Aceite de Cookies </v-card-title>
			<v-card-text class="text-justify pb-0">
				<p v-if="politicaAtualizada"><b>ATENÇÃO</b>: Nossa Política de Cookies foi atualizada!</p>
				<p>
					Utilizamos cookies para analisar como você interage em nosso site e poder oferecer melhor
					experiência, maior desempenho e conteúdo personalizado. Ao utilizar a Sovis/Store, você
					concorda com o uso de Cookies e a Política de Privacidade de Dados.
					<router-link :to="{name: 'PoliticaDeCookies',}" class="secondary--text">
						Saiba mais
					</router-link>
				</p>
			</v-card-text>
			<v-card-actions class="pt-2">
				<v-btn color="warning" text @click="showCookieAccept = false">Recusar Cookies</v-btn>
				<v-spacer />
				<v-btn color="success lighten-3" text @click="aceptCookies()">
					Aceitar Cookies
				</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>
<script>
export default {
	name: 'CookieAccept',
	data() {
		return {
			showCookieAccept: false,
			versaoCookies: null,
			politicaAtualizada: false
		};
	},
	created() {
		this.getLatestCookieVersion();
	},
	methods: {
		aceptCookies() {
			let timeToExpire = '1m';
			this.$cookies.set('cookieAccepted', this.versaoCookies, timeToExpire);
			this.showCookieAccept = false;
			this.politicaAtualizada = false;
		},
		getLatestCookieVersion(){
			this.$axios
				.get('termo/versao_cookies_web')
				.then((response) => {
					if (response.data.data != null) {
						this.versaoCookies = response.data.data;
					} else {
						throw new this.ErrorMsg('without-data');
					}
				})
				.catch((error) => {
					let msg = this.getTranslation(error.response.data.message, 'without-data');
					this.$toast.warning(msg);
				})
				.finally(() => {
					if(this.$cookies.get('cookieAccepted') == null){
						this.showCookieAccept = true;
					}else if (this.$cookies.get('cookieAccepted') != this.versaoCookies) {
						this.showCookieAccept = true;
						this.politicaAtualizada = true;
					}
				});
		},
	},
};
</script>
<style lang="scss" scoped>
.cookie-accept {
	position: fixed;
	bottom: 20px;
	left: 20px;
	max-width: 400px;
	max-height: 400px;
	overflow-y: auto;
	a {
		color: #fff;
		&:hover {
			color: var(--v-secondary-lighten2);
		}
	}
}
</style>
