// Para carregamento por demanda
let lazyLoad = (view) => {
	return () => import(`@/pages/views/${view}`);
};

export default [
	{
		path: '/store/:friendlyurl/:filial',
		name: 'Store',
		component: lazyLoad('ClienteStore.vue'),
		children: [
			{
				path: 'home',
				name: 'HomeProdutos',
				component: lazyLoad('clientstore/HomeProdutos.vue'),
				meta: {
					title: 'Home',
				},
			},
			{
				path: 'filter',
				name: 'FilterProdutos',
				component: lazyLoad('clientstore/FilterProdutos.vue'),
				meta: {
					title: 'Produtos',
				},
			},
			{
				path: 'product/:id',
				name: 'Product',
				component: lazyLoad('clientstore/Product.vue'),
				meta: {
					title: 'Produto',
				},
			},
			{
				path: 'cart',
				name: 'Cart',
				component: lazyLoad('clientstore/Cart.vue'),
				meta: {
					title: 'Carrinho',
					requiresAuthentication: true,
				},
			},
			{
				path: 'user',
				name: 'UserClient',
				component: lazyLoad('clientstore/User.vue'),
				children: [
					{
						path: 'meus-dados',
						name: 'MeusDadosClient',
						component: lazyLoad('clientstore/user/MeusDados.vue'),
						meta: {
							title: 'Meus dados',
							requiresAuthentication: true,
						},
					},
					{
						path: 'cadastro',
						name: 'CadastroCliente',
						component: lazyLoad('clientstore/user/CadastroCliente.vue'),
						meta: {
							title: 'Cadastro',
							requiresAuthentication: true,
						}
					},
					{
						path: 'pedido',
						name: 'Pedido',
						component: lazyLoad('clientstore/user/Pedido.vue'),
						children: [
							{
								path: 'list',
								name: 'PedidoList',
								component: lazyLoad('clientstore/user/pedido/PedidoList.vue'),
								meta: {
									title: 'Pedidos',
									requiresAuthentication: true,
									scrollToTop: true,
								},
							},
							{
								path: ':id',
								name: 'PedidoView',
								component: lazyLoad('clientstore/user/pedido/PedidoView.vue'),
								meta: {
									title: 'Pedido',
									requiresAuthentication: true,
								},
							},
						],
					},
					{
						path: 'endereco',
						name: 'Endereco',
						component: lazyLoad('clientstore/user/Endereco.vue'),
						children: [
							{
								path: 'list',
								name: 'EnderecoList',
								component: lazyLoad('clientstore/user/endereco/EnderecoList.vue'),
								meta: {
									title: 'Endereços',
									requiresAuthentication: true,
								},
							},
							{
								path: 'new',
								name: 'EnderecoNew',
								component: lazyLoad('clientstore/user/endereco/EnderecoManage.vue'),
								meta: {
									title: 'Endereço novo',
									requiresAuthentication: true,
								},
							},
							{
								path: 'edit/:id',
								name: 'EnderecoEdit',
								component: lazyLoad('clientstore/user/endereco/EnderecoManage.vue'),
								meta: {
									title: 'Editar endereço',
									requiresAuthentication: true,
								},
							},
						],
					},
				],
			},
		],
	},
];
