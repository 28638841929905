import Vue from 'vue';
import VueRouter from 'vue-router';
// import store from '@/vuex/vuex';

// Importa rotas de outros arquivos
import clientstore from '@/router/clientstore';
import placestore from '@/router/placestore';
import user from '@/router/user';
import info from '@/router/info';

Vue.use(VueRouter);

// Título inicial no navegador
let title = 'Sovis Store';

// Para carregamento por demanda
let lazyLoad = (view) => {
	return () => import(`@/pages/views/${view}`);
};

const routes = [
	// Dados carregados de outro arquivo
	...placestore,
	...clientstore,
	...user,
	...info,

	// Rota para download do aplicativo
	{
		path: '/DownloadMobileApp',
		name: 'DownloadMobileApp',
		component: lazyLoad('DownloadMobileApp.vue'),
	},
	// Quando entra-se no / redireciona para /home
	{
		path: '/',
		redirect: { name: 'PlaceStore' },
	},
	// para pasar-se url amigável com rota limpa
	{
		path: '/:friendlyurl',
		name: 'friendlyurl',
		meta: {
			title: 'friendlyurl',
		},
	},
	// Quando a url não é capturada por nenhuma das regras acima é mandado para a página de erro
	{
		path: '/:notfriendlyurl',
		name: 'Error404Page',
		component: lazyLoad('Error404Page.vue'),
		meta: {
			title: 'Page not found',
		},
	},
	// rota de erro
	{
		path: '*',
		component: lazyLoad('Error404Page.vue'),
		meta: {
			title: 'Page not found',
		},
	},
];

const router = new VueRouter({
	routes,
	mode: 'history',
	scrollBehavior() {
		// envia para o topo ao atualizar a rota
		return { x: 0, y: 0 };   //(TO-DO) se não funcionar, usar: { top: 0, behavior: 'smooth' };
	},
});

router.beforeEach((to, from, next) => {
	// caso esteja tentando ir da mesma rota para ela mesma cancela a ída
	if (from.fullPath === to.fullPath) {
		next(false);
		return;
	}
	// para passar a url amigável com rota limpa
	let expression = /^[a-zA-Z0-9-]*$/;
	let regex = new RegExp(expression);
	if (to.name == 'friendlyurl') {
		let valid = regex.test(to.params.friendlyurl);

		if (valid && to.params.friendlyurl != 'store') {
			next({
				name: 'HomeProdutos',
				params: { friendlyurl: to.params.friendlyurl, filial: 'catalogo' },
			});
			return;
		} else {
			console.warn('Página não encontrada:', to.params.friendlyurl);
			if ($cookies.get('friendlyurl')) $cookies.remove('friendlyurl');
			if ($cookies.get('filial')) $cookies.remove('filial');
			next({
				name: 'Error404Page',
				params: { notfriendlyurl: to.params.friendlyurl },
			});
			return;
		}
	}
	// Adiciona o cookie com o nome amigável da loja
	if (to.params.friendlyurl && regex.test(to.params.friendlyurl)) {
		$cookies.set('friendlyurl', to.params.friendlyurl, '1m');
		if (to.params.filial && (to.params.filial == 'catalogo' || !isNaN(+to.params.filial))) {
			$cookies.set('filial', to.params.filial, '1m');
		}
	}

	next();
});

router.afterEach((to) => {
	Vue.nextTick(() => {
		// concatena o título com título da rota
		document.title = `${title} • ${to.meta.title}` || title;
	});
});

export default router;
