import store from '@/vuex/vuex';

export const recaptcha = {
	init: () => {
		let recaptchaScript = document.createElement('script')
		recaptchaScript.setAttribute('src', "https://www.google.com/recaptcha/api.js?render="+process.env.VUE_APP_RECAPTCHA_SITEKEY)
		recaptchaScript.setAttribute("async", "true")
		recaptchaScript.setAttribute("defer", "true")
		document.head.appendChild(recaptchaScript)
	},

	/**
	 * Função para gerar token do Recaptcha, o qual deve ser enviado ao servidor para verificação de comportamento do usuário
	 * @param {*} action URL da requisição a ser verificada
	 * @param {*} callback Função a ser chamada após aquisição do token (preferencialmente: função que contenha apenas a chamada ao axios)
	 */
	recaptchaExecute: (action, callback) => {
		grecaptcha.ready(function() {
			grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITEKEY, {action: action})
				.then(function(token) {
					store.dispatch('recaptchaToken', { value: token });
					store.dispatch('recaptchaAction', { value: action });
					callback();
				});
		});
	}
}