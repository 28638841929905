export default {
	// verificação se está em um aparelho mobile
	mobile: false,
	// título da página
	title: 'Sovis B2B',
	// cores da sovis
	primaryColor: '#673ab7',
	secondaryColor: '#ff5722',
	// verifica se usuário está logado
	authenticated: false,
	// dados do usuário logado
	user: null,
	// dados do cliente
	cliente: null,
	// filiais do cliente
	filialList: [],
	// filial selecionada
	filialSelected: null,
	// tabela de preço do cliente
	tabpreco: null,
	// tipo de pedido do cliente
	tipopedido: null,
	// lista de prazos de pagamento
	prazopagtoList: [],
	// prazo de pagamento selecionado
	prazopagtoselected: null,
	// lista de formas de pagamento
	formapagtoList: [],
	// forma de pagamento selecionado
	formapagtoselected: null,
	// url amigável do cliente
	friendlyUrl: null,
	// data do últmo login
	ultimologin: null,
	// manter usuário logado
	keepLogged: false,
	// chama função de pegar dados do usuário
	getUserInfo: false,
	// chama função de logout do usuário
	logoutUser: false,
	// quando é mobile ou a resolução é baixa
	downloadMobileAppPage: false,
	// chama a função para abrir escolha de filial
	openChoiceFilial: false,
	// chama a função para abrir escolha de categoria
	openCategorias: false,
	// chama a função para abrir escolha de clientes vinculados
	openMeusClientes: false,
	// módulo atual
	currentModule: {
		placestore: false,
		clientstore: false,
		user: false,
		info: false,
	},
	// objeto do pedido pendente
	pedidopendente: null,
	// lista de itens do pedido pendente
	itenspedido: [],
	// lista de Modulos PDA com respectivos Modulos PDA Detalhe
	modulosPDA: [],
	// lista de tuplas que armazena os IDs relevantes à identificação de erros para quando um produto de valor zerado é exibido
	errosValorZerado: [],
	// key utilizada para recarregar appBarClientStore quando necessário
	appBarClientStoreKey: 0,
	// key utilizada para recarregar userModal quando necessário
	userModalKey: 0,
	// key utilizada para recarregar userMenu quando necessário
	userMenuKey: 0,
	// passa String com token do recaptcha para o axios
	recaptchaToken: "",
	// passa Action do recaptcha (definido como a URL da requisição que usa recaptcha) para garantir funcionamento correto da ferramenta
	recaptchaAction: "",
	// numero maximo de destinatários para os quais o e-mail de recuperação de senha pode ser enviado em um intervalo de 10 minutos
	numDestinatarios: 10,
	// lista que armazena o nome das tabelas com as quais há integração no ERP
	hasIntegracao: [],
	// objeto que armazena estado da tela FilterProdutos para possibilitar que usuário retorne à tela com mesmo estado
	filterProdutosScrenState: {
		scrollX: 0,
		scrollY: 0,
		orderBySelected: 'asc',
		perPage: 0,
		currentPage: 1,
		fullPath: null
	},
	// quantidade de produtos indisponíveis que precisam ser atualizados no carrinho devido a mudanças no estoque
	produtosIndisponiveisToUpdate: 0,
	//objeto para armazenar configs vindas da tabela CONFIG
	configs: {
		//booleano referente à config EXIBECODIGOPRODUTO, que determina se código de produto corresponde ao IDPRODUTOERP ou REFERENCIA
		//se V, código corresponde a REFERENCIA. se F, a IDPRODUTOERP. padrão é F
		isCodigoProdutoFromReferencia: false,
		//booleano referente à config ESCONDESALDOPRODUTO, que determina se saldo disponível dos produtos deve ser exibido ou não
		//padrão é F (não esconde)
		isescondesaldo: false,
		//booleano referente às configs RELACIONAFORMAPRAZO e RELACIONAPRAZOFORMA. Se uma delas estiver ativa, associação deve ser usada
		//padrão é F (não usa associação)
		isformaprazoassociationused: false,
		//booleano que indica se forma de pagamento deve ser selecionada antes do prazo ou vice-versa
		//Se T -> Forma deve ser selecionada primeiro. Se F -> Prazo deve ser selecionado primeiro
		isformafirst: true,
		//booleano que indica se IPI, ST/ICMS ou FECOEP são usados no sistema (coluna CALCULAIPI na tabela EMPRESA, moduloPDA ICMSST e moduloPDA TOTALFECOEP, respectivamente)
		//se T -> "total com impostos" é exibido no carrinho
		isimpostosused: false
	},
	// *NOTE - lista de parâmetros do clientStore, aqui vão os parâmetros de Ordenação e Agrupamento do clientStore, já previamente aplicados no WEB-FV.
	parametrosClientStore: null,
};
