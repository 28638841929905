export default {
	mobile: (state, { value }) => {
		state.mobile = value;
	},
	title: (state, { value }) => {
		state.title = value;
	},
	authenticated: (state, { value }) => {
		state.authenticated = value;
	},
	user: (state, { value }) => {
		state.user = value;
	},
	ultimologin: (state, { value }) => {
		state.ultimologin = value;
	},
	keepLogged: (state, { value }) => {
		state.keepLogged = value;
	},
	getUserInfo: (state, { value }) => {
		state.getUserInfo = value;
	},
	downloadMobileAppPage: (state, { value }) => {
		state.downloadMobileAppPage = value;
	},
	logoutUser: (state, { value }) => {
		state.logoutUser = value;
	},
	openChoiceFilial: (state, { value }) => {
		state.openChoiceFilial = value;
	},
	openCategorias: (state, { value }) => {
		state.openCategorias = value;
	},
	openMeusClientes: (state, { value }) => {
		state.openMeusClientes = value;
	},
	cliente: (state, { value }) => {
		state.cliente = value;
	},
	clienteSituacao: (state, { value }) => {
		state.cliente.situacao = value;
	},
	filialList: (state, { value }) => {
		state.filialList = value;
	},
	filialSelected: (state, { value }) => {
		state.filialSelected = value;
	},
	tabpreco: (state, { value }) => {
		state.tabpreco = value;
	},
	tipopedido: (state, { value }) => {
		state.tipopedido = value;
	},
	prazopagtoList: (state, { value }) => {
		state.prazopagtoList = value;
	},
	prazopagtoselected: (state, { value }) => {
		state.prazopagtoselected = value;
	},
	formapagtoList: (state, { value }) => {
		state.formapagtoList = value;
	},
	formapagtoselected: (state, { value }) => {
		state.formapagtoselected = value;
	},
	friendlyUrl: (state, { value }) => {
		state.friendlyUrl = value;
	},
	// sela somente o módulo enviado como ativo
	currentModule: (state, { value }) => {
		Object.entries(state.currentModule).forEach(([key, val]) => {
			if(key!=value&&val) state.currentModule[key] = false;
		});
		state.currentModule[value] = true;
	},
	pedidopendente: (state, { value }) => {
		state.pedidopendente = value;
	},
	itenspedido: (state, { value }) => {
		state.itenspedido = value;
	},
	modulosPDA: (state, { value }) => {
		state.modulosPDA = value;
	},
	errosValorZerado: (state, { value }) => {
		state.errosValorZerado = value;
	},
	appBarClientStoreKey: (state, { value }) => {
		state.appBarClientStoreKey = value;
	},
	userModalKey: (state, { value }) => {
		state.userModalKey = value;
	},
	userMenuKey: (state, { value }) => {
		state.userMenuKey = value;
	},
	recaptchaToken: (state, { value }) => {
		state.recaptchaToken = value;
	},
	recaptchaAction: (state, { value }) => {
		state.recaptchaAction = value;
	},
	numDestinatarios: (state, { value }) => {
		state.numDestinatarios = value;
	},
	hasIntegracao: (state, { value }) => {
		state.hasIntegracao = value;
	},
	filterProdutosScrenState: (state, { value }) => {
		state.filterProdutosScrenState = value;
	},
	produtosIndisponiveisToUpdate: (state, { value }) => {
		state.produtosIndisponiveisToUpdate = value;
	},
	configs: (state, { value }) => {
		state.configs = value;
	},
	parametrosClientStore: (state, { value }) => {
		state.parametrosClientStore = value;
	}
};